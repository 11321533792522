import { useLocation, useNavigate } from "react-router-dom";
import { AddButton, PlusIcon, HeaderContainer, HeaderTitle, HeaderText, dropDownstyles, ExcelIcon } from "../Header/Header.Styled";
import Select, { SingleValue } from "react-select";
import { OptionType } from "../../pages/types";
import { statusFilterOptions } from "../../constants";
import { HiOutlineViewList } from "react-icons/hi";
import { FaPlus } from "react-icons/fa";

type Props = {
    title: string;
    buttonTitle?: string | null;
    text?: string | null;
    languages?: OptionType[];
    filterStatus?: SingleValue<OptionType>;
    filter?: boolean;
    currentLang?: SingleValue<OptionType>;
    accounts?: boolean;
    moduleTitle?: string | null;
    onClick?: () => void;
    onClickEdit?: () => void;
    onChangeLang?: (newValue: SingleValue<OptionType>) => void;
    onChangeFilter?: (newValue: SingleValue<OptionType>) => void;
}
const Header: React.FC<Props> = ({ title, text, buttonTitle, languages, currentLang, accounts, filterStatus, filter, moduleTitle, onClick, onClickEdit, onChangeLang, onChangeFilter }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const handleClick = () => {
        if (onClick) {
            onClick()
        }
    }
    const handleLangChange = (newValue: SingleValue<OptionType>) => {
        if (onChangeLang) {
            onChangeLang(newValue)
        }
    }
    const handleFilterChange = (newValue: SingleValue<OptionType>) => {
        if (onChangeFilter) {
            onChangeFilter(newValue)
        }
    }

    const handleClickEdit = () => {
        if (onClickEdit) {
            onClickEdit()
        }
    }
    
    return (
        <HeaderContainer>
            <div>
                <HeaderTitle>
                    {title}
                </HeaderTitle>
                {text &&
                    <HeaderText>
                        {text}
                    </HeaderText>}
            </div>
            <div style={{ display: 'flex' }}>
                {filter && <Select
                    styles={dropDownstyles}
                    value={filterStatus}
                    onChange={handleFilterChange}
                    options={statusFilterOptions}
                    isSearchable={false}
                />}
                {buttonTitle && <AddButton onClick={handleClick} className="me-3">
                    {accounts ? <ExcelIcon src={require('../../assets/excel.png')} /> :
                        <FaPlus size={12} className="me-1"/>}
                    {buttonTitle}
                </AddButton>}
                {moduleTitle && <AddButton onClick={handleClickEdit}>
                    <HiOutlineViewList size={18} className="me-1"/>
                    {moduleTitle}
                </AddButton>}
                {languages && <div className="ms-3">
                    <Select
                        styles={dropDownstyles}
                        value={currentLang}
                        onChange={handleLangChange}
                        options={languages}
                        isSearchable={false}
                    />
                </div>}
            </div>
        </HeaderContainer>

    )
}
export default Header;
